import React, { lazy, Suspense, useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { Layout } from 'antd'
import { useDispatch, useSelector } from 'react-redux'

import SiderMenu from 'components/layouts/SiderMenu'
import MainHeader from 'components/layouts/MainHeader'
import PageBreadcrumb from 'components/layouts/Breadcrumb'
import Spinner from 'components/commons/Spinner'
import { LoadingTrigger } from 'components/commons/GlobalLoading'

import { checkIsLoggedIn } from 'utils/common'
import { getGeneralInfo } from 'states/general/actions'

import routes from 'routes'

const { Content } = Layout

const AppLayout = (props) => {
  const general = useSelector(state => state.general)
  const dispatch = useDispatch()
  const isLoggedIn = checkIsLoggedIn()

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getGeneralInfo())
    }
  }, [])

  if (!isLoggedIn) {
    return null
  }

  if (general.loading) {
    return <LoadingTrigger />
  }

  return (
    <Layout hasSider className="h-screen">
      <SiderMenu />
      <Layout>
        <MainHeader />
        <Content className="mt-16 p-4">
          <PageBreadcrumb />
          <div className="bg-white p-6 relative h-full" style={{ overflowY: 'scroll'}}>
            <Suspense fallback={<Spinner fullPage />}>
              <Switch>
                <Redirect from={routes.app()} to={routes.dashboard()} exact />
                <Route path={routes.dashboard()} component={lazy(() => import('pages/Dashboard'))} />
                <Route path={routes.admins()} component={lazy(() => import('pages/Admins'))} />
                <Route path={routes.users()} component={lazy(() => import('pages/Users'))} />
                <Route path={routes.userDetail()} component={lazy(() => import('pages/UserDetail'))} />
                <Route path={routes.projects()} component={lazy(() => import('pages/Projects'))} />
                <Route path={routes.projectDetail()} component={lazy(() => import('pages/ProjectDetail'))} />
                <Route path={routes.holidays()} component={lazy(() => import('pages/Holidays'))} />
                <Route path={routes.emailTemplates()} component={lazy(() => import('pages/EmailTemplates'))} />
                <Route path={routes.timesheets()} component={lazy(() => import('pages/Timesheets'))} />
                <Route path={routes.reports()} component={lazy(() => import('pages/Reports'))} />
              </Switch>
            </Suspense>
          </div>
        </Content>
      </Layout>
    </Layout>
  )
}

export default AppLayout
