import React, { useEffect, memo } from 'react'

import { useGlobalLoading } from './hooks'

const LoadingTrigger = () => {
  const { startGlobalLoading, endGlobalLoading } = useGlobalLoading()

  useEffect(() => {
    startGlobalLoading()
    return () => endGlobalLoading()
  }, [startGlobalLoading, endGlobalLoading])

  return null
}

export default memo(LoadingTrigger)
