import axios from 'axios'
import toastMessage from 'utils/toast'
import { getLocalStorage } from 'utils/storage'
import { logout } from './auth'
import { API_DOMAIN } from 'constants/common'
import { STORAGE_KEYS } from 'constants/common'
import { getAccessToken } from 'utils/common'

const baseFetch = (url, options) => {
  const requestURL = `${API_DOMAIN}${url}`

  return axios({
      url: requestURL,
      validateStatus: function (status) {
        return (status >= 200 && status < 300) || (status >= 400 && status < 500)
      },
      ...options })
    .then(response => {
      if (response.status >= 400 && response.status < 500) {
        if (response.status === 401) {
          toastMessage.error('Unauthorized')
          logout()
        } else {
          throw new Error(response.data.details && response.data.details[0] || response.data.message || 'Something went wrong!')
        }
      }

      return response.data
    }).catch(error => {
      throw error
    })
}

export const getRequest = (url, params) => {
  const accessToken = getAccessToken()
  const requestOptions = {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    params
  }

  return baseFetch(url, requestOptions)
}

export const postRequest = (url, body) => {
  const accessToken = getAccessToken()
  const requestOptions = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    data: body
  }

  return baseFetch(url, requestOptions)
}

export const putRequest = (url, body) => {
  const accessToken = getAccessToken()
  const requestOptions = {
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    data: body
  }

  return baseFetch(url, requestOptions)
}

export const deleteRequest = (url, body) => {
  const accessToken = getAccessToken()
  const requestOptions = {
    method: 'DELETE',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${accessToken}`
    },
    data: body
  }

  return baseFetch(url, requestOptions)
}
