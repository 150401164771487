import ACTION_TYPES from './const'

export const getGeneralInfo = () => ({
  type: ACTION_TYPES.GET_GENERAL_INFO,
})

export const getGeneralInfoSuccess = (generalInfo) => ({
  type: ACTION_TYPES.GET_GENERAL_INFO_SUCCESS,
  payload: generalInfo,
})

export const getGeneralInfoFailed = () => ({
  type: ACTION_TYPES.GET_GENERAL_INFO_FAILED,
})
