import {
  put, all, call, takeLatest,
} from 'redux-saga/effects'

import generalService from 'services/general'

import toastMessage from 'utils/toast'

import * as actions from './actions'

import ACTION_TYPES from './const'
import { isAdmin, isSuperAdmin } from 'utils/common'
import { USER_ROLES_MAP, CONFIG } from 'constants/common'

function* getGeneralInfo() {
  try {
    const res = yield call(generalService.getUserData)

    const userInfo = {
      ...res,
      role: USER_ROLES_MAP[res.permissions],
      isAdmin: isAdmin(res.permissions),
      isSuperAdmin: isSuperAdmin(res.permissions),
      workingDays: CONFIG.officerWorkingDays,
      dailyWorkingHours: CONFIG.dailyWorkingHours,
      role: USER_ROLES_MAP[res.permissions],
    }
    yield put(actions.getGeneralInfoSuccess({ userInfo, config: CONFIG }))
  } catch (error) {
    yield put(actions.getGeneralInfoFailed())
    toastMessage.error(error.message)
  }
}

function* watchGetGeneralInfo() {
  yield takeLatest(ACTION_TYPES.GET_GENERAL_INFO, getGeneralInfo)
}

export default function* globalSagas() {
  yield all([watchGetGeneralInfo()])
}
