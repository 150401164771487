import { postRequest, getRequest, putRequest } from './base'
import { removeLocalStorage } from 'utils/storage'
import { STORAGE_KEYS } from 'constants/common'
import routes from 'routes'

export const loginWithGoogle = async (accessToken) => {
  return postRequest('/auth/google/callback', {
    access_token: accessToken,
    accessType: 1 // access from admin tool
  })
}

export const logout = async () => {
  // await getRequest('​/auth​/logout')
  removeLocalStorage(STORAGE_KEYS.ACCESS_TOKEN)
  window.location.href = routes.login()
}

export default {
  loginWithGoogle,
  logout
}
