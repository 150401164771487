import React, { useState, useMemo, memo } from 'react'

import LoadingOverlay from '../LoadingOverlay'

import LoadingContext from './LoadingContext'

const GlobalLoading = ({ children }) => {
  const [counter, setCounter] = useState(0)

  const contextValues = useMemo(() => ({
    startLoading: () => setCounter(prev => prev + 1),
    endLoading: () => setCounter(prev => Math.max(prev - 1, 0))
  }), [])

  return (
    <LoadingContext.Provider value={contextValues}>
      {counter > 0 && <LoadingOverlay />}
      {children}
    </LoadingContext.Provider>
  )
}

export default memo(GlobalLoading)
