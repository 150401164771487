import parse from 'html-react-parser'
import { isAfter, add } from 'date-fns'
import { getLocalStorage, setLocalStorage } from './storage'
import { USER_ROLES, USER_ROLES_MAP, STORAGE_KEYS } from '../constants/common'

export const getBase64FromImage = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file)
  reader.onload = () => resolve(reader.result)
  reader.onerror = (error) => reject(error)
})


export const checkIsLoggedIn = () => {
  try {
    const token = JSON.parse(getLocalStorage(STORAGE_KEYS.ACCESS_TOKEN))
    if (isAfter(new Date(), new Date(token.expiredAt))) {
      return false
    }
    return !!token.accessToken
  } catch (error) {
    return false
  }
}

export const setAccessToken = (tokenObj) => {
  const expiredAt = add(new Date(), { seconds: tokenObj.expiresIn }).valueOf()
  setLocalStorage(STORAGE_KEYS.ACCESS_TOKEN, JSON.stringify({ ...tokenObj, expiredAt }))
}

export const getAccessToken = () => {
  if (checkIsLoggedIn()) {
    const token = JSON.parse(getLocalStorage(STORAGE_KEYS.ACCESS_TOKEN))
    return token.accessToken
  }
  return ''
}

export const isSuperAdmin = (roleNumber) => {
  return USER_ROLES_MAP[roleNumber] === USER_ROLES.SuperAdmin
}

export const isAdmin = (roleNumber) => {
  return USER_ROLES_MAP[roleNumber] === USER_ROLES.Admin
}

export const isCompanyEmail = (email) => {
  return /^.+@ykkapfacade\.com$/.test(email)
}

export const parseHtmlString = (string) => {
  return parse(string)
}