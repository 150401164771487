import React, { memo, useMemo } from 'react'
import { Breadcrumb } from 'antd'

const PageBreadcrumb = () => {
  return null
  return (
    <Breadcrumb className="mb-4">
      <Breadcrumb.Item>Home</Breadcrumb.Item>
      <Breadcrumb.Item>List</Breadcrumb.Item>
      <Breadcrumb.Item>App</Breadcrumb.Item>
    </Breadcrumb> 
  )
}

export default memo(PageBreadcrumb)
