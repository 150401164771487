import { put, all, call, takeLatest } from 'redux-saga/effects'

import authService from 'services/auth'

import toastMessage from 'utils/toast'
import { setLocalStorage } from 'utils/storage'

import * as actions from './actions'

import ACTION_TYPES from './const'
import { STORAGE_KEYS } from 'constants/common'
import { setAccessToken } from 'utils/common'

function* loginWithGoogle(action) {
  try {
    const res = yield call(authService.loginWithGoogle, action.payload)
    setAccessToken(res.token)
    // setLocalStorage(STORAGE_KEYS.ACCESS_TOKEN, res.token.accessToken)
    yield put(actions.loginWithGoogleSuccess(res))
  } catch (error) {
    yield put(actions.loginWithGoogleFailed())
    toastMessage.error('Invalid Gmail account!')
  }
}

function* watchLoginWithGoogle() {
  yield takeLatest(ACTION_TYPES.LOGIN_WITH_GOOGLE, loginWithGoogle)
}

export default function* globalSagas() {
  yield all([watchLoginWithGoogle()])
}
