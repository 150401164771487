const routes = {
  login: () => '/login',
  app: () => '/',
  dashboard: () => '/dashboard',
  admins: () => '/admins',
  users: () => '/users',
  userDetail: (userId = ':userId') => `/user/${userId}`,
  projects: () => '/projects',
  projectDetail: (projectId = ':projectId') => `/project/${projectId}`,
  holidays: () => '/holidays',
  emailTemplates: () => '/email-templates',
  timesheets: () => '/timesheets',
  reports: () => '/reports',
}

export default routes