export const SETUP_ENVIRONMENT = process.env.REACT_APP_SETUP_ENVIRONMENT
export const API_DOMAIN = process.env.REACT_APP_API_DOMAIN_DEV
export const GCP_CLIENT_ID = process.env.REACT_APP_GCP_CLIENT_ID

export const STORAGE_KEYS = {
  ACCESS_TOKEN: 'ACCESS_TOKEN',
}

export const VALIDATE_MESSAGES = {
  required: '${label} is required!',
  types: {
    email: '${label} is not valid!',
    number: '${label} is not a valid!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
}

export const GENDER_OPTIONS = [
  { label: 'Male', value: 1 },
  { label: 'Female', value: 2 },
]

export const REGION_CODE = {
  HK: '+852',
}

export const SORT_VALUE = {
  ascend: 'ASC',
  descend: 'DESC',
}

export const USER_ROLES = {
  SuperAdmin: 'Super Admin',
  Admin: 'Admin',
  User: 'User',
}

export const USER_ROLES_MAP = {
  511: USER_ROLES.SuperAdmin,
  123: USER_ROLES.Admin,
  1: USER_ROLES.User,
}

export const CONFIG = {
  dailyWorkingHours: 8,
  // siteWorkerWorkingDays: [1, 2, 3, 4, 5, 6],
  // officerWorkingDays: [1, 2, 3, 4, 5, 6],
  siteWorkerWorkingDays: [0, 1, 2, 3, 4, 5, 6],
  officerWorkingDays: [0, 1, 2, 3, 4, 5, 6],
  calendarColor: {
    fullFill: '#0084b8',
    halfFill: '#FF7828',
    hourFill: '#2C9F30FF',
    noFill: '#000',
  },
}
