import React from 'react'

import GlobalLoading from './GlobalLoading'

const withGlobalLoading = (Component) => {
  return (props) => (
    <GlobalLoading>
      <Component {...props} />
    </GlobalLoading>
  )
}

export default withGlobalLoading
