import { combineReducers } from 'redux'
import generalReducer from './general/reducer'
import authReducer from './auth/reducer'

const appReducer = combineReducers({
  general: generalReducer,
  auth: authReducer
})

export default appReducer
