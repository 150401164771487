import ACTION_TYPES from './const'

// login with google
export const loginWithGoogle = (accessToken) => ({
  type: ACTION_TYPES.LOGIN_WITH_GOOGLE,
  payload: accessToken
})

export const loginWithGoogleSuccess = (authData) => ({
  type: ACTION_TYPES.LOGIN_WITH_GOOGLE_SUCCESS,
  payload: authData,
})

export const loginWithGoogleFailed = () => ({
  type: ACTION_TYPES.LOGIN_WITH_GOOGLE_FAILED,
})
