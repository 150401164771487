import React, { memo, useMemo } from 'react'
import {
  Layout, Dropdown, Menu, Avatar,
} from 'antd'
import { useSelector } from 'react-redux'
import { logout } from 'services/auth'

const { Header } = Layout

const MainHeader = () => {
  const { userInfo } = useSelector(state => state.general)
  const userMenu = useMemo(() => {
    return (
      <Menu>
        <Menu.Item key="0">
          {`${userInfo.firstName} ${userInfo.surName} (${userInfo.role})`}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item key="1" className="hover:text-primary" onClick={logout}>
          Logout
        </Menu.Item>
      </Menu>
    )
  }, [userInfo])

  return (
    <Header className="fixed w-full">
      <div className="fixed right-4">
        <Dropdown overlay={userMenu} trigger={['click']}>
          <Avatar src={userInfo.image} className="cursor-pointer" />
        </Dropdown>
      </div>
    </Header>
  )
}

export default memo(MainHeader)
