import React, { memo, useCallback, useEffect } from 'react'
import GoogleLogin from 'react-google-login'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button } from 'antd'
import {
  useGlobalLoading,
  LoadingTrigger,
} from 'components/commons/GlobalLoading'
import { loginWithGoogle } from 'states/auth/actions'
import toastMessage from 'utils/toast'
import routes from 'routes'
import { GCP_CLIENT_ID } from 'constants/common'
import Logo from 'assets/images/ykk_logo.png'
import Google from 'assets/images/Google.png'
import wcircles from 'assets/images/Workcircle_Logo_white.png'

//import './Login.css'

const Login = () => {
  const { startGlobalLoading, endGlobalLoading } = useGlobalLoading()
  const { authData, loading } = useSelector(state => state.auth)
  const history = useHistory()

  const dispatch = useDispatch()

  const handleSuccess = useCallback(async response => {
    dispatch(loginWithGoogle(response.accessToken))
  }, [])

  const handleFailure = useCallback(response => {
    console.log('response: ', response)
  }, [])

  useEffect(() => {
    if (authData.token && authData.token.accessToken) {
      history.push(routes.app())
    }
  }, [authData])

  return (
    <div className="h-screen w-screen flex flex-col	items-center">
      {loading && <LoadingTrigger />}
      <div className="pt-200px">
        <img src={Logo} alt="" />
      </div>
      <div className="pt-8">
        <GoogleLogin
          clientId={GCP_CLIENT_ID}
          buttonText="Sign in with Google"
          onSuccess={handleSuccess}
          onFailure={handleFailure}
          scope="email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile"
        />
      </div>
    </div>
    // <div className="loginDiv">
    //   {loading && <LoadingTrigger />}

    //   <div className="leftPanel">
    //     <div className="contentContainer">
    //       <p>Built by</p>
    //       <a href="https://connet.one" rel="noopener" target="_blank">
    //         <div className="wcircles">
    //           <img src={wcircles} alt="" />
    //         </div>
    //       </a>
    //     </div>
    //   </div>

    //   <div className="rightPanel">
    //     <img id="YKKLogo" src={Logo} alt="" />
    //     <div className="googleDiv">
    //       <GoogleLogin
    //         className="googleButton"
    //         clientId={GCP_CLIENT_ID}
    //         buttonText="Sign in with Google"
    //         onSuccess={handleSuccess}
    //         onFailure={handleFailure}
    //         scope="email profile https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile"
    //         render={renderProps => (
    //           <button
    //             className="googleButton"
    //             onClick={renderProps.onClick}
    //             disabled={renderProps.disabled}
    //           >
    //             <div className="imgContainer">
    //               <img src={Google} alt="" />
    //             </div>
    //             <span>Sign in with Google</span>
    //           </button>
    //         )}
    //       />
    //     </div>
    //   </div>
    // </div>
  )
}

export default memo(Login)
