import ACTION_TYPES from './const'

const initialState = {
  userInfo: {},
  loading: false,
}

function generalReducer(state = initialState, action) {
  switch (action.type) {
    case ACTION_TYPES.GET_GENERAL_INFO:
      return { ...state, loading: true }
    case ACTION_TYPES.GET_GENERAL_INFO_SUCCESS:
      return { ...state, ...action.payload, loading: false }
    case ACTION_TYPES.GET_GENERAL_INFO_FAILED:
      return { ...state, loading: false }
    default:
      return state
  }
}

export default generalReducer
