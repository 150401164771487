import React from 'react'
import ReactDOM from 'react-dom'

import Spinner from '../Spinner'

const LoadingOverlay = () => {
  return (
    ReactDOM.createPortal(
      <Spinner fullPage />,
      document.getElementsByTagName('body')[0]
    )
  )
}

export default LoadingOverlay
